import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Text from '../../../../../../../../../../components/Text'
import IconWeather from '../../../../../../../IconWeather'

const Wrapper = styled.div`
  max-width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 65px;
`

const Name = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 1.2;
  white-space: nowrap;
`

const Description = styled(Text)`
  color: ${(props) => props.theme.color.textPrimary};
  line-height: 1.2;
  width: 187px;
  text-align: center;
`
const SymbolsForecastMountainMap = ({ name, iconName, description, ...props }) => {
  return (
    <Wrapper {...props}>
      <IconWeather name={iconName} description={description} />
      <Name>{name}</Name>
      <Description>{description}</Description>
    </Wrapper>
  )
}

SymbolsForecastMountainMap.propTypes = {
  name: PropTypes.string,
  iconName: PropTypes.string,
  description: PropTypes.string,
}

export default SymbolsForecastMountainMap

export { Description as SymbolsForecastMountainMap0Description }
