import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import translate from '../../../../../../../../modules/translate'
import SymbolForecastMountain from './components/SymbolForecastMountain'

const StyledSymbolForecastMountain = styled(SymbolForecastMountain)``

const Wrapper = styledContainerQuery.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.theme.sizes.gapCol};
  justify-content: space-between;
  padding: 15px 0;

  &:container(max-width: 400px){
    justify-content: center;
    ${StyledSymbolForecastMountain} {
      width: 50%;
    }
  }
`
const SymbolsForecastMountain = ({ altitudeWind, snowLimit, zeroLimit, ...props }) => {
  let snowLimitString = ''
  if (snowLimit) {
    snowLimitString = snowLimit[0]
    if (snowLimit[1]) {
      snowLimitString = snowLimit[1] + ' - ' + snowLimitString
    }
    snowLimitString += 'm'
  }
  return (
    <Wrapper {...props}>
      {
        // eslint-disable-next-line
        altitudeWind && (
          <StyledSymbolForecastMountain
            title={translate('weather_mountain_wind')}
            text={altitudeWind.description}
            force={altitudeWind.force}
            direction={altitudeWind.direction}
          />
        )
      }
      {snowLimit && snowLimit.length > 0 && (
        <StyledSymbolForecastMountain
          title={translate('weather_mountain_snow_limit')}
          iconName={'snow'}
          text={snowLimitString}
        />
      )}
      {
        // eslint-disable-next-line
        zeroLimit && (
          <StyledSymbolForecastMountain
            title={translate('weather_mountain_zero_limit')}
            iconName="zero"
            text={zeroLimit + 'm'}
          />
        )
      }
    </Wrapper>
  )
}

SymbolsForecastMountain.propTypes = {
  altitudeWind: PropTypes.shape({
    direction: PropTypes.string,
    force: PropTypes.number,
    description: PropTypes.string,
  }),
  snowLimit: PropTypes.array,
  zeroLimit: PropTypes.string,
}

export default SymbolsForecastMountain

export {
  Wrapper as SymbolsForecastMountain0Wrapper,
  StyledSymbolForecastMountain as SymbolsForecastMountain0StyledSymbolForecastMountain,
}
