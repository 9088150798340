import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import southTyrolMountain from './images/southTyrolMountain.png'
import styledContainerQuery from 'styled-container-query'
import translate from '../../../../../../../../modules/translate'
import SymbolsForecastMountainMap, {
  SymbolsForecastMountainMap0Description,
} from './components/SymbolsForecastMountainMap'

const Wrapper = styled.div``

const ResponsiveWrapper = styledContainerQuery.div`
  position: relative;
  display: inline-block;
  padding-top: ${(props) => props.theme.sizes.gapCol};

  &:container(max-width: 470px) {
    ${SymbolsForecastMountainMap0Description} {
      display: none;
    }
  }
`

const StyledSymbolsForecastMountainMap = styled(SymbolsForecastMountainMap)`
  height: 100px;
  transform: translate(-40px, -30px);
  position: absolute;

  ${(props) =>
    props.location === 'nord' &&
    css`
      top: 25%;
      left: 50%;
    `} ${(props) =>
    props.location === 'south' &&
    css`
      top: 70%;
      left: 50%;
    `};
`

const ImageMap = styled.img`
  width: 100%;
  max-width: 640px;
  display: block;
  height: auto;
  opacity: 0.8;
`

const MapItems = styledContainerQuery.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const MapForecastMountain = ({ data, ...props }) => {
  if (data) {
    return (
      <Wrapper {...props}>
        <ResponsiveWrapper>
          <ImageMap src={southTyrolMountain} />
          <MapItems>
            {data.north && (
              <StyledSymbolsForecastMountainMap
                name={translate('weather_mountain_north')}
                iconName={data.north.code}
                description={data.north.description}
                location="nord"
              />
            )}
            {data.south && (
              <StyledSymbolsForecastMountainMap
                name={translate('weather_mountain_south')}
                iconName={data.south.code}
                description={data.south.description}
                location="south"
              />
            )}
          </MapItems>
        </ResponsiveWrapper>
      </Wrapper>
    )
  }
  return <div {...props} />
}

MapForecastMountain.propTypes = {
  data: PropTypes.shape({
    north: PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
    south: PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
}

export default MapForecastMountain
