import React from 'react'
import { SymbolsForecastMountain0Wrapper } from '../..'
import SymbolForecastMountainSkeleton from '../../components/SymbolForecastMountain/views/SymbolForecastMountainSkeleton'

const SymbolsForecastMountainSkeleton = ({ ...props }) => (
  <SymbolsForecastMountain0Wrapper {...props}>
    <SymbolForecastMountainSkeleton code="A" />
    <SymbolForecastMountainSkeleton />
  </SymbolsForecastMountain0Wrapper>
)

export default SymbolsForecastMountainSkeleton
