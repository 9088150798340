import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import images from './images'
import Text from '../../../../../../../../../../components/Text'
import WindRose from './component/WindRose'
import Title from '../../../../../../../../../../components/Title'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${(props) => props.theme.sizes.col4};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col4};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col4};
  }
`

const Icon = styled.img`
  height: 40px;
  margin: 15px 0.4em;
  opacity: 0.2;
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stol};
  margin: 0;
  text-align: center;
`

StyledTitle.defaultProps = {
  tag: 'h3',
}

const StyledText = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  text-align: center;
`
const EmptyIcon = styled(Icon)``

const SymbolForecastMountain = ({ iconName, title, text, force, direction, ...props }) => {
  let words = ''
  let lastWord = ''
  if (force) {
    words = text.split(' ')
    lastWord = words.pop()
  }
  return (
    <Wrapper {...props}>
      {
        // eslint-disable-next-line
        (force && <WindRose force={force} direction={direction} />) ||
          // eslint-disable-next-line
          (iconName in images && <Icon src={images[iconName]} />) || <EmptyIcon as="div" />
      }

      <StyledTitle>{title}</StyledTitle>
      {
        // eslint-disable-next-line
        (force && (
          <StyledText>
            {words.join(' ')}
            <br />
            <strong>{lastWord}</strong>
          </StyledText>
        )) || (
          // eslint-disable-next-line
          <StyledText>{text}</StyledText>
        )
      }
    </Wrapper>
  )
}

SymbolForecastMountain.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  force: PropTypes.number,
  direction: PropTypes.string,
}

export default SymbolForecastMountain

export {
  Wrapper as SymbolForecastMountain0Wrapper,
  images as SymbolForecastMountain0Images,
  Icon as SymbolForecastMountain0Icon,
}
