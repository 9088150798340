import React from 'react'
import ForecastPrognosis from '../ForecastPrognosis'
import TablesForecastMountain from './components/TablesForecastMountain'
import PropTypes from 'prop-types'
import SymbolsForecastMountain from './components/SymbolsForecastMountain'
import MapForecastMountain from './components/MapForecastMountain'
import styledContainerQuery from 'styled-container-query'
import styled from 'styled-components'

const StyledMapForecastMountain = styled(MapForecastMountain)`
  align-self: center;
`

const StyledElementBelowTitle = styledContainerQuery.div`
  display: flex;
  flex-direction: column;

  &:container(max-width: 370px) {
    ${StyledMapForecastMountain} {
      margin-left: -${props => props.theme.sizes.gapEdge};
      margin-right: -${props => props.theme.sizes.gapEdge};
    }
  }
`

const ForecastMountain = ({ data, ...props }) => {
  let forecastData = data && data.data
  let forecastTable = data && data.table
  return (
    <ForecastPrognosis
      weatherType="mountain"
      {...(forecastTable &&
        forecastData.mapData &&
        forecastData && {
          elementBelowTitle: (
            <StyledElementBelowTitle>
              <StyledMapForecastMountain data={forecastData.mapData} />
              <SymbolsForecastMountain
                altitudeWind={forecastData.altitudeWind}
                snowLimit={forecastData.snowLimit}
                zeroLimit={forecastData.zeroLimit}
              />
            </StyledElementBelowTitle>
          ),
        })}
      data={forecastData}
      {...props}
    />
  )
}

ForecastMountain.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      ...ForecastPrognosis.propTypes,
      table: PropTypes.shape({
        ...TablesForecastMountain.propTypes,
      }),
      ...MapForecastMountain.propTypes,
      ...SymbolsForecastMountain.propTypes,
    }),
    PropTypes.shape({}),
  ]),
}

ForecastMountain.defaultProps = {
  table: {},
  data: {},
}

export default ForecastMountain
