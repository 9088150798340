import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import star from './images/star.svg'
import starArrow from './images/star_arrow.svg'
import { Motion, spring } from 'react-motion'
import Delay from '../../../../../../../../../../../../components/Delay'

const Wrapper = styled.div`
  position: relative;
  padding: 5px;
  height: 60px;
  width: 60px;
`

const Icon = styled.img`
  display: block;
  opacity: 0.2;
  height: 100%;
`

const ArrowIcon = styled.img`
  display: block;
  height: 100%;
`

const ArrowIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
`

const Speed = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  background-color: ${(props) => props.theme.color.background};
  border-radius: 50%;
  text-align: center;
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

const directionParse = (direction) => {
  const map = {
    A: 0,
    B: 45,
    C: 90,
    D: 135,
    E: 180,
    F: -135,
    G: -90,
    H: -45,
  }

  return map[direction] || 0
}

const WindRose = ({ direction, force, ...props }) => {
  const directionDegrees = directionParse(direction)

  return (
    <Wrapper {...props}>
      <Icon src={star} />
      <Delay value={directionDegrees}>
        {(value) => (
          <Motion defaultStyle={{ degrees: 0 }} style={{ degrees: spring(value) }}>
            {({ degrees }) => (
              <ArrowIconWrapper>
                <ArrowIcon src={starArrow} style={{ transform: `rotate(${degrees}deg)` }} />
              </ArrowIconWrapper>
            )}
          </Motion>
        )}
      </Delay>
      <Speed>{force}</Speed>
    </Wrapper>
  )
}

WindRose.propTypes = {
  direction: PropTypes.string,
  force: PropTypes.number,
}

WindRose.defaultProps = {
  direction: 'A',
  force: 0,
}

export default WindRose
