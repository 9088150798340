import React from 'react'
import ForecastPrognosisSkeleton from '../../../ForecastPrognosis/views/ForecastPrognosisSkeleton'
import MapForecastMountain from '../../components/MapForecastMountain'
import SymbolsForecastMountainSkeleton from '../../components/SymbolsForecastMountain/views/SymbolsForecastMountainSkeleton'
import TextSkeleton from '../../../../../../../../components/Text/views/TextSkeleton'

const ForecastMountainSkeleton = ({ ...props }) => (
  <ForecastPrognosisSkeleton
    elementBelowTitle={
      <div>
        <MapForecastMountain data={{}} />
        <SymbolsForecastMountainSkeleton />
      </div>
    }
    textBlocks={
      <div>
        <TextSkeleton fontSize="24px" lastWidth="40%" lineHeight={2} />
        <TextSkeleton lines={6} lastWidth="60%" />
      </div>
    }
    {...props}
  >
    Loading
  </ForecastPrognosisSkeleton>
)

export default ForecastMountainSkeleton
