import React from 'react'
import { SymbolForecastMountain0Icon, SymbolForecastMountain0Images, SymbolForecastMountain0Wrapper } from '../..'
import WindRose from '../../component/WindRose'
import TextSkeleton from '../../../../../../../../../../../../components/Text/views/TextSkeleton'

const SymbolForecastMountainSkeleton = ({ code, ...props }) => {
  return (
    <SymbolForecastMountain0Wrapper {...props}>
      {
        // eslint-disable-next-line
        (code && <WindRose code={code} />) || (
          // eslint-disable-next-line
          <SymbolForecastMountain0Icon src={SymbolForecastMountain0Images['zero']} />
        )
      }
      <TextSkeleton lines={2} lastWidth="80%" align="center" />
    </SymbolForecastMountain0Wrapper>
  )
}

export default SymbolForecastMountainSkeleton
