import snow from './snow.svg'
import star from '../component/WindRose/images/star.svg'
import star_arrow from '../component/WindRose/images/star_arrow.svg'
import zero from './zero.svg'

export default {
  snow: snow,
  star: star,
  star_arrow: star_arrow,
  zero: zero,
}
