import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextCopyrightWeather from '../../../../components/TextCopyrightWeather/index'
import ForecastMountain from '../../../../components/Forecast/views/ForecastMountain/index'
import ForecastMountainSkeleton from '../../../../components/Forecast/views/ForecastMountain/views/ForecastMountainSkeleton/index'
import MessageError from '../../../../../Error/screens/MessageError/index'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary/index'
import translate from '../../../../../../modules/translate/index'
import ContainerAd from '../../../../../../components/ContainerAd/index'
//import WidgetMountainWeather from '../../../widgets/WidgetMountainWeather' TODO add this later

const Wrapper = styled.div``

const errorOptions = {
  component: translate('weather_mountain_error_today'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'MountainWeather screen display',
}

const BorderContainer = styled.div`
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
`

const StyledBorder = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.textTertiary};
`

const MountainWeather = ({ loading, failed, data, ...props }) => (
  <Wrapper {...props}>
    <ErrorBoundary error={failed} {...errorOptions}>
      {loading ? (
        <ForecastMountainSkeleton />
      ) : data && data.today ? (
        <ForecastMountain data={data.today} isTomorrow={false} />
      ) : (
        <MessageError logInfo={translate('weather_mountain_error_today')} {...errorOptions} />
      )}
    </ErrorBoundary>
    {/*
              <ErrorBoundary
        error={failed}
        {...errorOptions} >
        {loading ? <ForecastMountainSkeleton />
            : (data && data.today ?
            <WidgetMountainWeather
              table={data.today.table}
              sunName={data.today.sunName}
              sunRise={data.today.sunRise}
              sunSet={data.today.sunSet}
              moonName={data.today.moonName}
              moonRise={data.today.moonRise}
              moonSet={data.today.moonSet}
            />
            : <MessageError logInfo={translate('weather_mountain_error_today')} {...errorOptions} />)
        }
      </ErrorBoundary>
        */}
    <ContainerAd adIds={['S7']} />
    <BorderContainer>
      <StyledBorder />
    </BorderContainer>
    <ErrorBoundary error={failed} {...errorOptions}>
      {loading ? <ForecastMountainSkeleton /> : data && <ForecastMountain data={data.tomorrow} isTomorrow />}
    </ErrorBoundary>
    <ContainerAd adIds={['S8']} />
    <TextCopyrightWeather />
  </Wrapper>
)

MountainWeather.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  today: ForecastMountain.propTypes.data,
  tomorrow: ForecastMountain.propTypes.data,
}

MountainWeather.defaultProps = {
  loading: false,
  failed: false,
  data: {},
}

export default MountainWeather
